import { CourseImage, MappedCourse } from "./MappedCourse"
import { getCourseDescriptionType, slugifyCourse } from "./common"
import { createSeedCourseNumber } from "../bank/createSeedCourseNumber"
import { htmlSerializer } from "./htmlSerializer"
import { CourseAgeGroup, CourseType, CourseVariant } from "./Course"
import { isCourseRegistrationAfter, isCourseRegistrationBefore, isCourseRegistrationOpen } from "./conditions"
import { getCourseTypeUrl, getTechnologyUrl } from "./mapData"
import { PrismicDocument, FilledImageFieldImage } from "@prismicio/types"
import { RichText } from "prismic-reactjs"
import moment from "moment"
import { asText } from "@prismicio/helpers"
import { RTEmbedNode } from "@prismicio/types/src/value/richText"
import { Asset, Entry } from "contentful"
import { TypeCreatorSkeleton, TypePhotoGallerySkeleton, TypeProductEntitySkeleton } from "../contentful/ContentfulVzdelanibudoucnostiTypes"
import { getProductSlug } from "../contentful/product/getProductSlug"
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { documentToRenderOptions } from "../../components/Layout/documentToRenderOptions"
import dayjs from "dayjs"
import { PrismicCourseOrContentfulProduct } from "../contentful/PrismicCourseOrContentfulProduct"

const extractVideoUrl = (content: any[]) => {
  return content.filter((c) => c.type === "embed") as RTEmbedNode[]
}

const filterOutYoutubeEmbeddedVideos = (content: any[]) => {
  return content.filter((c) => !(c.type === "embed" && c.oembed?.type === "video"))
}

const mapOldPrismicCourse = (d: PrismicDocument<any>): MappedCourse => {
  const { slugify_name, number_id, slug } = slugifyCourse(d)

  return {
    id: d.id,
    slugifyName: slugify_name,
    numberId: number_id,
    slug,
    seedNumber: createSeedCourseNumber(d.id),
    name: (d.data.title[0] && d.data.title[0].text) || "",
    excerpt: d.data?.excerpt ? <RichText render={d.data.excerpt} htmlSerializer={htmlSerializer} /> : null,
    excerptAsText: (d.data.excerpt[0] && d.data.excerpt[0].text) || "",
    additionalInformation:
      d.data?.additional_information?.length > 0 ? <RichText render={d.data.additional_information} htmlSerializer={htmlSerializer} /> : null,
    content: d.data?.content ? <RichText render={filterOutYoutubeEmbeddedVideos(d.data.content)} htmlSerializer={htmlSerializer} /> : null,
    embeddedContentVideoUrls: d.data?.content ? extractVideoUrl(d.data.content).map((u) => u.oembed.embed_url) : undefined,
    rate: 4,
    difficulty: Number(d.data.difficulty),
    ageGroup: d.data.age_group && d.data.age_group !== "" ? d.data.age_group : CourseAgeGroup.children,
    type: d.data.type && d.data.type !== "" ? d.data.type : CourseType.online,
    descriptionType: getCourseDescriptionType(d.data.age_group as CourseAgeGroup, d.data.type as CourseType),
    courseVariant: d.data.course_type as CourseVariant,
    when: (d.data.when[0] && d.data.when[0].text) || "",
    where: (d.data.where[0] && d.data.where[0].text) || "",
    moreUrl: `${getCourseTypeUrl(d.data.age_group as CourseAgeGroup)}/${slug}`,
    registerUrl: `/registrace/${slug}`,
    typeUrl: getCourseTypeUrl(d.data.age_group as CourseAgeGroup),
    price: d.data.price,
    capacity: d.data.capacity,
    registrationOpenFrom: d.data.registration_open_from,
    registrationOpenTo: d.data.registration_open_to,
    firstCourseDay: d.data?.first_course_day ? moment(d.data?.first_course_day) : undefined,
    lastCourseDay: d.data?.last_course_day ? moment(d.data?.last_course_day) : undefined,
    isOpen: isCourseRegistrationOpen(d),
    isBeforeRegistration: isCourseRegistrationBefore(d),
    isAfterRegistration: isCourseRegistrationAfter(d),
    image: d.data.content.find((c) => c.type === "image") || undefined,
    equipmentPrice: d.data.equipment_price || undefined,
    equipmentInfo: d.data.equipment_info ? <RichText render={d.data.equipment_info} htmlSerializer={htmlSerializer} /> : undefined,
    embed: d.data?.embed,
    onlineMeetingDescription: d.data?.online_meeting_description ? (
      <RichText render={d.data.online_meeting_description} htmlSerializer={htmlSerializer} />
    ) : undefined,
    onlineMeetingDescriptionText: d.data?.online_meeting_description ? asText(d.data?.online_meeting_description) : undefined,
    onlineMeetingLink: d.data?.online_meeting_link?.url || undefined,
    tags: d?.tags || [],
    photoGallery: (d.data?.["photo_gallery"]?.data?.images || []).map((g) => g.image) as FilledImageFieldImage[],
    subCourse: d.data?.["sub_course"]?.data ? getCourse(d.data?.["sub_course"]) : undefined,
    technologyUrl: getTechnologyUrl(d.data.technology),
    sampleCourseVideoUrl: d.data?.sample_course_video_url?.url,
    highlighted: d.data?.highlighted
  }
}

const getProductDescriptionType = (p: Entry<TypeProductEntitySkeleton, undefined, string>): CourseType => {
  switch (p.fields.type) {
    case "onlineCourse":
      return CourseType.online
    case "inPersonCourse":
      return CourseType.inPerson
    case "inPersonEvent":
      return CourseType.inPerson
    case "onlineEvent":
      return CourseType.onlineEvent
    default:
      return CourseType.product
  }
}

const getProductCourseVariant = (p: Entry<TypeProductEntitySkeleton, undefined, string>): CourseVariant => {
  switch (p.fields.subType) {
    case "sampleCourse":
      return CourseVariant.sampleCourse
    case "workshopCourse":
      return CourseVariant.workshop
    case "course":
      return CourseVariant.course
    case "projectDaysCourse":
      return CourseVariant.projectDays
    default:
      return CourseVariant.product
  }
}

export const isProductAvailable = ({ fields: { availableTo, availableFrom } }: Entry<TypeProductEntitySkeleton, undefined, string>): boolean => {
  return availableFrom && availableTo && dayjs().isAfter(dayjs(availableFrom)) && dayjs().isBefore(dayjs(availableTo))
}

export const isProductHighlighted = ({ fields: { highlighted } }: Entry<TypeProductEntitySkeleton, undefined, string>): boolean => {
  return highlighted
}

const isProductCourseRegistrationBefore = ({ fields: { availableFrom } }: Entry<TypeProductEntitySkeleton, undefined, string>): boolean => {
  return availableFrom && moment().isBefore(moment(availableFrom))
}

const isProductCourseRegistrationAfter = ({ fields: { availableTo } }: Entry<TypeProductEntitySkeleton, undefined, string>): boolean => {
  return availableTo && moment().isSameOrAfter(moment(availableTo))
}

export const getProductCourseImage = ({
  fields: { image }
}: Entry<TypeProductEntitySkeleton, undefined, string> | Entry<TypeCreatorSkeleton, undefined, string>): CourseImage | undefined => {
  const imageAsset = image ? (image as Asset<undefined, string>) : undefined
  if (!imageAsset) {
    return undefined
  }

  return {
    alt: imageAsset.fields.file.fileName,
    copyright: null,
    dimensions: {
      width: imageAsset.fields.file.details.image.width,
      height: imageAsset.fields.file.details.image.height
    },
    type: "image",
    url: `https:${imageAsset.fields.file.url}`
  }
}

const getPhotoGallery = ({ fields: { pictureGallery } }: Entry<TypeProductEntitySkeleton, undefined, string>): FilledImageFieldImage[] => {
  const existingPictureGallery =
    pictureGallery?.["fields"]?.["images"]?.length > 0 ? (pictureGallery as Entry<TypePhotoGallerySkeleton, undefined, string>) : undefined

  if (!existingPictureGallery) {
    return []
  }

  return existingPictureGallery.fields.images.map((i) => {
    const imageAsset = i ? (i as Asset<undefined, string>) : undefined
    if (!imageAsset) {
      return undefined
    }

    return {
      alt: imageAsset.fields.file.fileName,
      copyright: null,
      dimensions: {
        width: imageAsset.fields.file.details.image.width,
        height: imageAsset.fields.file.details.image.height
      },
      type: "image",
      url: `https:${imageAsset.fields.file.url}`
    }
  })
}

const mapContentfulProduct = (p: Entry<TypeProductEntitySkeleton, undefined, string>): MappedCourse => {
  const {
    fields: {
      title,
      content,
      description,
      price,
      perex,
      additionalInformation,
      difficulty,
      when,
      where,
      capacity,
      availableFrom,
      availableTo,
      courseFirstDay,
      courseLastDay,
      recordingUrl,
      embeddedContentVideoUrls,
      eventOnlineUrl,
      eventOnlineDescription
    },
    sys: { id },
    metadata: { tags }
  } = p

  const slug = getProductSlug(p)

  return {
    id,
    slugifyName: slug,
    numberId: 0,
    slug,
    seedNumber: createSeedCourseNumber(id),
    name: title,
    excerpt: <>{perex}</>,
    description: documentToReactComponents(description, documentToRenderOptions),
    descriptionAsText: documentToPlainTextString(description),
    excerptAsText: perex,
    additionalInformation: documentToPlainTextString(additionalInformation),
    content: documentToReactComponents(content, documentToRenderOptions),
    embeddedContentVideoUrls: embeddedContentVideoUrls || undefined,
    rate: 0,
    difficulty,
    ageGroup: CourseAgeGroup.children,
    type: getProductDescriptionType(p),
    descriptionType: "",
    courseVariant: getProductCourseVariant(p),
    when: when?.length > 0 ? <>{when}</> : undefined,
    where: where?.length > 0 ? <>{where}</> : undefined,
    registerUrl: `/registrace/${slug}`,
    typeUrl: "/pocitacove-krouzky-pro-deti",
    price: price,
    capacity,
    registrationOpenFrom: availableFrom,
    registrationOpenTo: availableTo,
    firstCourseDay: moment(courseFirstDay),
    lastCourseDay: moment(courseLastDay),
    isOpen: isProductAvailable(p),
    isBeforeRegistration: isProductCourseRegistrationBefore(p),
    isAfterRegistration: isProductCourseRegistrationAfter(p),
    image: getProductCourseImage(p),
    highlighted: false,
    moreUrl: "",
    onlineMeetingDescriptionText: eventOnlineDescription,
    onlineMeetingLink: eventOnlineUrl,
    tags: tags.map((t) => t.sys.id),
    technologyUrl: "/",
    photoGallery: getPhotoGallery(p),
    recordingUrl
  }
}

export const getCourse = (d: PrismicCourseOrContentfulProduct): MappedCourse | undefined => {
  if (!d) {
    return undefined
  }

  if (isOldPrismicCourse(d)) {
    return mapOldPrismicCourse(d as PrismicDocument<any>)
  }

  return mapContentfulProduct(d as Entry<TypeProductEntitySkeleton, undefined, string>)
}

export const isOldPrismicCourse = (d: PrismicCourseOrContentfulProduct): boolean => !!d?.["id"]

export const isContentfulEshopProduct = (d: PrismicCourseOrContentfulProduct): boolean => {
  if (isOldPrismicCourse(d)) {
    return false
  }

  const p = d as Entry<TypeProductEntitySkeleton, undefined, string>
  return p.fields.type === "product" && p.fields.subType === "product"
}
