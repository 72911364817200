import React from "react"
import { ContentAboveContent, ContentAboveWrapper, whiteText } from "../Layout/common"
import FullwidthBgColor from "../Layout/FullWidthBgColor"
import Container from "../Layout/Container"
import { Typography } from "antd"
import { css } from "@emotion/react"
import { transparentize } from "polished"
import Image from "next/image"
import { CourseImage } from "../../lib/prismic/MappedCourse"

export const SinglePage = ({ title, color, className, children, bgImage }: Props) => {
  const finalColor = color || "#02589B"

  return (
    <>
      <section>
        <ContentAboveWrapper>
          <ContentAboveContent>
            <Typography.Title
              className="ml11"
              style={whiteText}
              css={css`
                text-align: center;
              `}>
              {title}
            </Typography.Title>
          </ContentAboveContent>
        </ContentAboveWrapper>

        <FullwidthBgColor color={bgImage?.url ? transparentize(0.25, finalColor) : finalColor} height="20vh">
          {bgImage?.url && (
            <Image
              alt={bgImage.alt || title}
              src={bgImage.url}
              fill
              style={{ objectFit: "cover" }}
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
              priority={true}
            />
          )}
        </FullwidthBgColor>
      </section>
      <Container className={className}>{children}</Container>
    </>
  )
}

interface Props {
  title: string
  color?: string
  className?: string
  bgImage?: CourseImage
  children: React.ReactNode
}
