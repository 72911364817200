import React from "react"
import { Col, Row } from "antd"
import Link from "next/link"
import styled from "@emotion/styled"
import { partnersList } from "../../lib/partnersList"
import Image from "next/image"
import { sm } from "../../styles/mediaQuery"

export const PartnersList = () => {
  return (
    <PartnersListWrapper className="page-white-border-r-block">
      <Row
        justify="space-around"
        gutter={{
          xs: 20,
          sm: 20,
          md: 60
        }}>
        {partnersList.map((p) => (
          <CenterCol key={p.name} xs={12} sm={8} md={6} xl={4}>
            <Link href={p.url} title={`Přejít na webové stránky ${p.name}`} target="_blank" rel="noopener noreferrer">
              <ImageSize>
                <Image
                  key={p.url}
                  src={p.imagePath}
                  alt={p.name}
                  fill
                  sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 170px"
                  style={{ objectFit: "contain" }}
                />
              </ImageSize>
            </Link>
          </CenterCol>
        ))}
      </Row>
    </PartnersListWrapper>
  )
}

const PartnersListWrapper = styled.div``

const CenterCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`

const ImageSize = styled.div`
  width: 150px;
  height: 150px;
  position: relative;

  ${sm} {
    width: 120px;
    height: 120px;
  }
`
