import React, { Suspense } from "react"
import Container from "../components/Layout/Container"
import { Layout } from "../components/Layout/Layout"
import { Col, List, Row, Space, Spin, Typography } from "antd"
import { ColoredBlock } from "../components/ColoredBlock/ColoredBlock"
import { css } from "@emotion/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { PartnersList } from "../components/PartnersList/PartnersList"
import { CollapsedPanel } from "../components/CollapsedPanel/CollapsedPanel"
import { Comments } from "../components/Comments/Comments"
import { GetStaticProps, GetStaticPropsResult } from "next"
import { getFeedbackAvatarUrl } from "../lib/prismic/courses"
import { NextSeo } from "next-seo"
import { webUrl } from "../config/web"
import { getArticlesWithAuthorsByDocumentType, PrismicCustomTypes } from "../lib/prismic/prosmic"
import Link from "next/link"
import { faqList } from "../lib/faq"
import { Section } from "../components/Section"
import { SubscribeForm } from "../components/SubscribeForm/SubscribeForm"
import { theme } from "../styles/styles"
import HighlightedArticle from "../components/blog/HighlightedArticle"
import { sortDocumentByDateTime } from "../lib/sortDateTime"
import { getTechnologyToolList } from "../lib/prismic/technologyTool"
import { TechnologyToolList } from "../components/TechnologyTools/TechnologyToolList"
import { PrismicDocument } from "@prismicio/types"
import { faAward, faBus, faLaptopCode, faPerson, faQuestion } from "@fortawesome/free-solid-svg-icons"
import { faPython } from "@fortawesome/free-brands-svg-icons"
import { IndexHero } from "../components/IndexHero/IndexHero"
import { getLandingPages, slugifyLandingPage } from "../lib/prismic/landingPages"
import styled from "@emotion/styled"
import { md } from "../styles/mediaQuery"
import { Bubble, Bubbles } from "../components/IndexHero/Bubbles"
import { ImageRounded } from "./organizace"
import { AdsWithColors, getAllVzAds } from "../lib/contentful/getAllVzAds"
import { dev } from "../lib/dev"
import { AllProduct, getAllProducts, getAllTechnologies, ProductsColorsItem } from "../lib/contentful/product/getAllProducts"
import { Entry, EntryCollection } from "contentful"
import { TypeProductEntitySkeleton, TypeTechnologySkeleton } from "../lib/contentful/ContentfulVzdelanibudoucnostiTypes"
import { getAvailableProducts } from "../lib/contentful/product/getAvailableProducts"
import dynamic from "next/dynamic"

const GroupedCourses = dynamic(() => import("../components/GroupedCourses/GroupedCourses").then((res) => res.GroupedCourses))
const Ads = dynamic(() => import("../components/Ad/Ads").then((res) => res.Ads))

const Index = ({ comments, technologies, postsResult, technologyTools, mappedBubbles, ads, contentfulProducts }: Props) => {
  const homePageComments = comments.map((d, i) => ({
    avatarImagePath: d.data.image.url || getFeedbackAvatarUrl(d.data.sex, i),
    author: d.data.name[0] ? d.data.name[0].text : "",
    content: d.data.description[0] ? d.data.description[0].text : "",
    dateTime: d.data.forcedFirstPublicationDate || d.first_publication_date
  }))

  const availableProductsAndCourses = getAvailableProducts(contentfulProducts)

  return (
    <>
      <NextSeo
        title="Kroužky programování pro děti 2025 | 9-15 let"
        description={`Bez IT se dnes v životě neobejdete. S programováním se stanete tvůrci světa, proto začněte hned teď! Nabízíme online kurzy pro děti od přibližně 9 let.`}
        canonical={webUrl}
        openGraph={{
          url: webUrl,
          title: "Kroužky programování pro děti 2025 | 9-15 let",
          description: `Bez IT se dnes v životě neobejdete. S programováním se stanete tvůrci světa, proto začněte hned teď! Nabízíme online kurzy pro děti od přibližně 9 let.`,
          images: [
            {
              url: `${webUrl}/images/01_2022_vzdelani_budoucnosti_web_header_1920_1.jpg`,
              width: 1920,
              height: 1080,
              alt: "01_2022_vzdelani_budoucnosti_web_header_1920_1"
            }
          ]
        }}
      />

      <Layout>
        <section>
          <IndexHero>
            <ShowWhenDesktop>
              <Bubbles mappedBubbles={mappedBubbles} />
            </ShowWhenDesktop>
          </IndexHero>
        </section>

        <Container>
          <Section>
            <ShowWhenMobile>
              <Bubbles mappedBubbles={mappedBubbles} />
            </ShowWhenMobile>
          </Section>

          <Suspense fallback={<Spin />}>
            <GroupedCourses courses={availableProductsAndCourses} productsColors={contentfulProducts?.productsColors || []} />
          </Suspense>

          {homePageComments.length > 0 && (
            <Section>
              <Typography.Title level={2}>Hodnocení</Typography.Title>

              <Space direction="vertical">
                <Comments comments={homePageComments} colProps={{ lg: 12 }} />

                <Link href="/recenze">Další hodnocení</Link>
              </Space>
            </Section>
          )}

          <Suspense fallback={<Spin />}>
            <Ads ads={ads} />
          </Suspense>

          <Section
            css={css`
              margin-top: 40px;
              margin-bottom: 30px;
            `}>
            <Space size={20} align="start">
              <div>
                <Typography.Paragraph>
                  Programování učím už několik let a pořád se nepřestávám divit, jak chápavé umí děti být a jak rychle může správně zvolená aktivita dítě
                  oslovit a nadchnout. Jen díky nadšení a opravdovému zájmu je možná cesta i ke složitějšímu IT, a právě tou my chceme s dětmi jít. Jestli
                  chcete poradit, co by bylo nejlepší zrovna pro vaše dítě, tak se nám ozvěte, moc rádi s vámi vše pobereme.
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "right" }}>Jakub Valenta, CEO projektu</Typography.Paragraph>
              </div>
              <div
                css={css`
                  width: 100px;
                  height: 100px;
                `}>
                <ImageRounded width={100} height={100} src="/images/organizace/jakub.jpg" alt="jakub" />
              </div>
            </Space>
          </Section>

          {postsResult.length > 0 && (
            <Section>
              <Typography.Title level={2}>
                Aktuálně z našeho{" "}
                <Link href="/blog" title="Přejít na blog">
                  blogu
                </Link>
              </Typography.Title>
              <Row
                gutter={[10, 10]}
                css={css`
                  margin: 20px 0 0 0;
                `}>
                {postsResult.map((post) => (
                  <Col key={post.id} lg={8} md={12} sm={12} xs={12}>
                    <HighlightedArticle
                      document={post}
                      titleAs="h3"
                      css={css`
                        margin-bottom: 0;
                        height: 320px;

                        > div {
                          box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
                        }
                      `}
                    />
                  </Col>
                ))}
              </Row>
            </Section>
          )}

          <Section
            css={css`
              margin-top: 40px;
              background-color: #fff;
              border-radius: 20px;
              overflow: hidden;
            `}>
            <Row>
              <ColoredBlock
                icon={
                  <FontAwesomeIcon
                    icon={faPerson}
                    size={"2x"}
                    css={css`
                      color: var(--main-color-blue);
                      height: 30px;
                    `}
                  />
                }
                title="Ideální student"
                content={
                  <Typography.Paragraph>
                    Baví tě hrát hry, sledovat filmy, trávit čas četbou na internetu a hledat nové informace. To vše musel někdo vytvořit. Jestli tě láká umět
                    vytvořit vlastní web, navrhnout stroj, napsat program, který pomůže stovkám lidí, pak jsi tady správně. Je to lehčí, než se zdá.
                  </Typography.Paragraph>
                }
              />
              <ColoredBlock
                icon={
                  <FontAwesomeIcon
                    icon={faBus}
                    size={"2x"}
                    css={css`
                      height: 30px;
                    `}
                  />
                }
                title="Vybavení ke kurzu"
                content={
                  <Typography.Paragraph>
                    Chcete se přihlásit ale máte slabý počítač, notebook nebo nemáte správné vybavení? Napište nám a spolu s organizací{" "}
                    <Link href="https://www.pocitacedetem.cz/" title="web Počítače dětem" target="_blank">
                      Počítače dětem
                    </Link>{" "}
                    se vám pokusíme pomoci. Můžete tak získat{" "}
                    <span
                      css={css`
                        color: var(--main-color-red);
                      `}>
                      počítač zdarma
                    </span>
                    . Mrkněte na náš{" "}
                    <Link
                      href="/blog/aktuality/znalosti-it-pomohou-vasim-detem-ziskat-nadstandardne-placenou-praci"
                      title="článek na na3em bolgu"
                      target="_blank">
                      blog
                    </Link>
                    .
                  </Typography.Paragraph>
                }
              />
            </Row>
            <Row>
              <ColoredBlock
                icon={
                  <FontAwesomeIcon
                    icon={faLaptopCode}
                    size={"2x"}
                    css={css`
                      color: var(--main-color-red);
                      height: 30px;
                    `}
                  />
                }
                title={
                  <>
                    Přístupné a{" "}
                    <span
                      css={css`
                        color: var(--main-color-red);
                      `}>
                      kreativní programování
                    </span>{" "}
                    pro všechny
                  </>
                }
                content={
                  <Typography.Paragraph>
                    Svůj budoucí život ovlivni již dnes. Nauč se programovat a připrav se tak na dobu, kdy stroje budou nahrazovat lidi. Někdo vše musí řídit.
                    Buď tou osobou ty!
                  </Typography.Paragraph>
                }
              />
              <ColoredBlock
                icon={
                  <FontAwesomeIcon
                    icon={faPython}
                    size={"2x"}
                    css={css`
                      color: var(--python-color);
                      height: 30px;
                    `}
                  />
                }
                title={
                  <>
                    Aktuálně se zabýváme technologiemi:{" "}
                    {technologies.length > 0 && (
                      <>
                        {technologies.map((t, i) => (
                          <Link key={t.sys.id} href={`/pocitacove-krouzky-pro-deti/${t.fields.slug}`}>
                            <WithHoverEffect color={t.fields.color ?? theme.colors[t.fields.slug]}>{t.fields.title}</WithHoverEffect>
                            {i === technologies.length - 2 ? " a " : i === technologies.length - 1 ? "" : ", "}
                          </Link>
                        ))}
                      </>
                    )}
                    .
                  </>
                }
                content={""}
              />
            </Row>
            <Row>
              <ColoredBlock
                icon={
                  <FontAwesomeIcon
                    icon={faQuestion}
                    size={"2x"}
                    css={css`
                      color: var(--main-color-blue);
                      height: 30px;
                    `}
                  />
                }
                title={
                  <>
                    <span
                      css={css`
                        color: var(--main-color-blue);
                      `}>
                      Proč
                    </span>{" "}
                    právě nás?
                  </>
                }
                content={
                  <List>
                    <List.Item>Učíme děti základy IT použít v praxi</List.Item>
                    <List.Item>Kvalitní a dostupné vzdělání pro všechny</List.Item>
                    <List.Item>Kroužky vedeme formou hry a zábavy</List.Item>
                    <List.Item>Lektoři jsou vybíráni z řad studentů a pedagogů vysokých technických škol</List.Item>
                  </List>
                }
              />
              <ColoredBlock
                icon={
                  <FontAwesomeIcon
                    icon={faAward}
                    size={"2x"}
                    css={css`
                      color: var(--main-color-red);
                      height: 30px;
                    `}
                  />
                }
                title={
                  <>
                    Naše{" "}
                    <span
                      css={css`
                        color: var(--main-color-red);
                      `}>
                      IT kroužky!
                    </span>
                  </>
                }
                content={
                  <Typography.Paragraph>
                    Naše kroužky jsou koncipované s ohledem na věk uchazeče. Jednoduše, jasně a s praktickými příklady využití IT v běžném životě. Názorné
                    ukázky pomáhají dětem i dospělým si lépe představit, co vše se jim pomocí programování v životě otevírá. Doporučujeme absolvování kroužků
                    všem, kteří chtějí najít uplatnění, dobrou hodnotnou práci a mít lepší budoucnost. Kurzy jsou také skvělou nadstavbou základního vzdělání.
                    Dětem rozšíří obzory a dodají možnosti do života, dospělým poskytnou příležitost pro změnu.
                  </Typography.Paragraph>
                }
              />
            </Row>
          </Section>

          <Section>
            <Typography.Title level={2}>Partneři</Typography.Title>
            <PartnersList />
          </Section>

          <Section>
            <Typography.Title level={2}>Odběr novinek</Typography.Title>
            <SubscribeForm tags={["Krouzky", "all", "index"]} />
          </Section>

          {technologyTools.length > 0 && (
            <Section>
              <Typography.Title level={2}>Používáme moderní technologie</Typography.Title>
              <TechnologyToolList technologyTools={technologyTools} />
            </Section>
          )}

          <Section>
            <Typography.Title level={2}>FAQ - často kladené dotazy</Typography.Title>
            <CollapsedPanel items={faqList} />
          </Section>
        </Container>
      </Layout>
    </>
  )
}

export const ShowWhenDesktop = styled.div`
  ${md} {
    display: none;
  }
`

export const ShowWhenMobile = styled.div`
  display: none;

  ${md} {
    display: block;
  }
`

const WithHoverEffect = styled.span<{ color: string }>`
  ${(props: { color: string }) => `
      color: ${props.color};
      transition: ${theme.effects.transition.default};
      
      &:hover {
        background-color: ${props.color};
        color: #fff;
        border-radius: 6px;
        box-shadow: -5px 0px 0px 0px ${props.color}, 5px 0px 0px 0px ${props.color};
      }
    `}
`

interface Props {
  technologies: Entry<TypeTechnologySkeleton, undefined, string>[]
  comments: PrismicDocument<any>[]
  postsResult: PrismicDocument<any>[]
  technologyTools: PrismicDocument<any>[]
  mappedBubbles: Bubble[]
  ads: AdsWithColors
  contentfulProducts: AllProduct | null
}

export const getStaticProps: GetStaticProps = async (): Promise<GetStaticPropsResult<Props>> => {
  const comments = await getArticlesWithAuthorsByDocumentType(PrismicCustomTypes.feedback, undefined, false, false, { pageSize: 4 })

  const contentfulTechnologies = await getAllTechnologies()
  const sortedTechnologies = contentfulTechnologies.items.sort((a, b) => a.fields.title.localeCompare(b.fields.title))

  const technologyToolsRes = await getTechnologyToolList()

  const blogPosts = await getArticlesWithAuthorsByDocumentType(PrismicCustomTypes.blogPost)
  const postsResult = blogPosts.results.filter((d: PrismicDocument) => !d.data.highlighted).sort(sortDocumentByDateTime) as PrismicDocument[]
  const highlightedPostsResult = blogPosts.results.filter((d: PrismicDocument) => d.data.highlighted).sort(sortDocumentByDateTime) as PrismicDocument[]

  const ads = await getAllVzAds()

  const bubblesLandingPages = await getLandingPages(["bublina-index"])
  const showOnIndex = ["Yn4o8xIAACwAoRqC", "Yn4ojBIAACwAoRif", "Yn4oYhIAAC0AoRfb"]
  const mappedBubbles: Bubble[] = (bubblesLandingPages.results || [])
    .filter((d) => showOnIndex.includes(d.id))
    .map((d) => ({
      title: d.data?.title?.[0]?.text || "",
      text: d.data?.perex?.[0]?.text || "",
      link: slugifyLandingPage(d).slug
    }))

  const contentfulProducts = await getAllProducts({ "fields.subType[in]": ["product", "course"] })

  return {
    props: {
      comments: comments.results.slice(0, 6) || [],
      postsResult: [...(highlightedPostsResult.slice(0, 1) || []), ...(postsResult?.slice(0, 2) || [])],
      technologyTools: technologyToolsRes.results || [],
      mappedBubbles,
      ads,
      contentfulProducts: contentfulProducts || {
        products: [] as unknown as EntryCollection<TypeProductEntitySkeleton, undefined, string>,
        productsColors: [] as ProductsColorsItem[]
      },
      technologies: sortedTechnologies ?? []
    },
    revalidate: dev ? 1 : 60
  }
}

export default Index
