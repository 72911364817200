export const slugAndIdDelimiter = "-d"

export const extractSlugAndId = (url: string): { slug?: string; id?: string } => {
  const delimiterIndex = url.lastIndexOf(slugAndIdDelimiter)
  if (delimiterIndex === -1) {
    return {}
  }

  const idAndRest = url.substring(delimiterIndex + slugAndIdDelimiter.length)
  const lastDashIndex = idAndRest.indexOf("-")
  const id = lastDashIndex === -1 ? idAndRest : idAndRest.substring(lastDashIndex + 1)

  const slug = url.substring(0, delimiterIndex)

  return { slug, id }
}
