import { AllProduct } from "./getAllProducts"
import { PrismicCourseOrContentfulProduct } from "../PrismicCourseOrContentfulProduct"
import { isProductAvailable } from "../../prismic/getCourse"
import { Entry } from "contentful"
import { TypeProductEntitySkeleton } from "../ContentfulVzdelanibudoucnostiTypes"

export const getAvailableProducts = (data?: AllProduct): PrismicCourseOrContentfulProduct[] => {
  return (data?.products?.items ?? []).filter((p) => isProductAvailable(p))
}

export const getAvailableAndFilterOutProducts = (id?: string, data?: AllProduct): Entry<TypeProductEntitySkeleton, undefined, string>[] => {
  if (!id) {
    return (data?.products?.items ?? []).filter((p) => isProductAvailable(p))
  }

  return (data?.products?.items ?? []).filter((p) => isProductAvailable(p) && p.sys.id !== id)
}
