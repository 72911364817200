import React from "react"
import { PrismicDocument } from "@prismicio/types"
import { CourseAgeGroup } from "./Course"
import { AuthorAndFeedbacks } from "./courses"
import { slugifyTechnology } from "./common"
import { CourseFeedback } from "./MappedCourse"
import { getCourseTechnology } from "./getCourseTechnology"
import { Entry } from "contentful"
import { TypeCreatorSkeleton, TypeProductEntitySkeleton } from "../contentful/ContentfulVzdelanibudoucnostiTypes"
import { PrismicCourseOrContentfulProduct } from "../contentful/PrismicCourseOrContentfulProduct"
import { getProductCourseImage } from "./getCourse"

export const getCourseTypeUrl = (ageGroup = CourseAgeGroup.adult): string =>
  ageGroup === CourseAgeGroup.adult ? "/kurzy-pro-dospele" : "/pocitacove-krouzky-pro-deti"

export const getTechnologyUrl = (d: PrismicDocument<any>): string => {
  const { slugifyName } = slugifyTechnology(d)

  return `/technologie/${slugifyName}`
}

export const getTechnology = (d: PrismicCourseOrContentfulProduct) => getCourseTechnology(d)

export const getLecturer = (d: AuthorAndFeedbacks | Entry<TypeProductEntitySkeleton, undefined, string>) => getCourseLecturer(d)

export const getFeedBacks = (d?: PrismicDocument<any>[]) => {
  return (d || []).map((f) => getFeedback(f))
}

export interface CourseTechnology {
  name?: string
  slug?: string
  description?: string
  content?: React.ReactNode
  color: string
}

export const getFeedback = (d: PrismicDocument<any>): CourseFeedback => ({
  description: d.data.description[0].text,
  imageUrl: d.data.image.url,
  name: (d.data.name[0] && d.data.name[0].text) || "",
  sex: d.data.sex,
  slug: d.uid || "",
  rate: d.data.rate,
  dateTime: d.data.forcedFirstPublicationDate || d.first_publication_date
})

export interface CourseLecturer {
  name: string
  email: string
  bio: React.ReactNode
  pictureUrl: string
  position: string
  more?: string
}

export const getCourseLecturer = (d: AuthorAndFeedbacks | Entry<TypeProductEntitySkeleton, undefined, string>): CourseLecturer => {
  const emptyLecturer = {
    name: "",
    email: "",
    bio: "",
    pictureUrl: "https://images.prismic.io/slicemachine-blank/30d6602b-c832-4379-90ef-100d32c5e4c6_selfie.png?auto=compress,format",
    position: "",
    more: undefined
  }

  const prismicData = d?.["id"] as AuthorAndFeedbacks | undefined
  if (prismicData) {
    if (!prismicData?.author?.data) {
      return emptyLecturer
    }

    return {
      name: prismicData.author.data.name?.[0]?.text || "",
      email: prismicData.author.data.email?.[0]?.text || "",
      bio: prismicData.author.data.bio?.[0]?.text || "",
      pictureUrl: prismicData.author.data.picture?.url || emptyLecturer.pictureUrl,
      position: prismicData.author.data.position?.[0]?.text || ""
    }
  }

  const fields = (d as Entry<TypeProductEntitySkeleton, undefined, string>)?.fields
  const creator = fields?.creator

  if (!creator || creator.length === 0) {
    return emptyLecturer
  }

  const firstCreator = creator[0] as Entry<TypeCreatorSkeleton, undefined, string> | undefined
  if (!firstCreator) {
    return emptyLecturer
  }

  return {
    name: firstCreator.fields?.name || "",
    email: firstCreator.fields?.email || "",
    bio: firstCreator.fields?.bio || "",
    pictureUrl: getProductCourseImage(firstCreator)?.url || emptyLecturer.pictureUrl,
    position: firstCreator.fields?.position || "",
    more: `/lektori/${firstCreator.fields.slug}`
  }
}
